<template>
  <div class="pb-10 lg:pb-24 z-20 relative">

    <div class="lg:hidden pt-3 mb-4">
      <div class="flex items-center justify-between text-sm font-medium w-full">
        <p class="text-base-600"> {{loc.current_balance}} FLEX </p>
        <p class="text-base-600">{{ monetary(user.balance, user.currency)}}</p>
      </div>

      <div class="w-100 border bg-white h-2 rounded-md">
        <div class="bg-base-600 h-2 rounded-md" :style="`width: ${calculateBudgetPercent(user.spent, user.base_balance)}%`"></div>
      </div>
    </div>
  </div>

  <div class="mt-5">
    <p class="text-gray-600 mb-6 text-sm">{{loc.flexible_desc}}</p>
    <additional-filters ref="additional_filters" />

    <ul  role="list" class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 py-4" v-if="benefits && (benefit_preview_type === 'grid' || benefits[0].fixed)">
      <li
          v-for="benefit in benefits"
          :key="benefit.id"
          class="h-72 col-span-1 bg-white rounded-lg shadow-xl cursor-pointer hover:text-base-500 text-gray-700 md:transform hover:scale-105 transition duration-200 ease-in-out"
          @click="$router.push(`/benefit/${benefit.id}`)"
      >
        <!--      <benefit-box :data="benefit" :key="benefit.id" />-->

        <div class="h-full w-full rounded-xl">

          <div v-if="benefit.fixed" :class="`w-full h-1/6 flex items-center justify-center transform translate-y-3`">
            <div :class="`flex items-center space-x-2 p-2  ${benefit.fixed ? 'w-full justify-center' : 'w-10/12'}`">
              <p :class="`text-sm font-medium text-ellipsis ${benefit.fixed? 'text-left': 'text-left'}`">{{benefit.name}}</p>
            </div>

            <!--            <div v-if="benefit.discount" class="w-2/12 bg-base-600 rounded-bl-lg text-white text-center font-semibold p-2 rounded-bl-xl">-{{benefit.discount}}%</div>-->
          </div>

          <div :class="`rounded-tr-xl rounded-tl-xl w-full ${benefit.fixed ? 'h-5/6' : 'h-3/6'} bg-gray-400 bg-cover`" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-position: center;`">
            <div v-if="benefit.discount" class="w-2/12 bg-base-600 text-white text-center font-semibold p-2 rounded-bl-xl rounded-tr-xl ml-auto">-{{benefit.discount}}%</div>
          </div>


          <div style="background-color: rgb(233,238,251);  height: 15%;" class="px-1 w-full flex justify-between items-center" v-if="benefits_type !== 'fixed'">
            <div class="w-7/12 flex items-center justify-start">
              <div v-if="benefit.price || benefit.discount" class="space-y-0">
                <p class="font-bold ml-1 leading-4" v-if="!benefit.nfc && !benefit.fixed && benefit.price > 0">{{benefit.price_discount_currency}}</p>
                <p class="font-medium text-gray-400 line-through text-sm md:text-base ml-1 mr-2 leading-3" v-if="!benefit.fixed && benefit.price > 0 && benefit.discount">{{benefit.price_currency}}</p>
                <div v-else></div>
              </div>
              <ul role="list" class="space-y-6" v-if="benefits && benefit_preview_type === 'list' && !benefits[0].fixed">
                <li
                    v-for="benefit in benefits"
                    class="h-24 col-span-1 bg-white rounded-lg cursor-pointer text-gray-700 shadow-xl md:transform hover:scale-105 transition duration-200 ease-in-out"
                    @click="$router.push(`/benefit/${benefit.id}`)"
                >
                  <div class="w-full h-full flex justify-between rounded-lg space-x-4">
                    <div class="rounded-l-lg bg-cover w-40 h-full shadow-2xl" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-size: contain; background-position: center center; background-repeat: no-repeat;`"/>
                    <p class="w-64 text-md font-bold my-auto">{{benefit.name}}</p>
                    <p class="w-64 text-xs my-2 font-medium cut-text text-gray-500 w-3/12 text-justify"> {{extractContent(benefit.short_description)}} </p>

                    <div class="ml-auto flex justify-end items-center w-4/12">
                      <div class="text-base-500 mr-4">
                        <p class="font-normal line-through" v-if="!benefit.fixed && benefit.price > 0 && benefit.discount">{{monetary(benefit.price, benefit.currency)}}</p>
                        <p class="font-semibold" v-if="!benefit.fixed && benefit.price > 0">{{monetary(benefit.price * (100 - benefit.discount) / 100, benefit.currency)}}</p>
                      </div>
                      <div class="flex w-1/3 pl-4 space-x-2">
                        <shopping-cart-icon v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0" @click.stop="toggleCart(benefit.id)" :class="`hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
                        <heart-icon v-if="benefits_type!='fixed'" @click.stop="toggleFavorites(benefit.id)" :class="`hover:text-red-600 ${userFavoriteIds.includes(benefit.id) ? 'text-red-600': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
                      </div>
                      <div v-if="benefit.discount" class="mb-auto bg-base-600 rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
                        -{{benefit.discount}}%
                      </div>
                      <!--          psudo element-->
                      <div v-else class="mb-auto bg-white rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="flex justify-center items-center px-1" v-if="(!benefit.nfc && !Number(benefit.price)) || (benefit.nfc)">
                <font-awesome-icon v-if="!benefit.nfc && !Number(benefit.price)" icon="fa-solid fa-file-invoice-dollar" />
                <font-awesome-icon v-if="benefit.nfc" icon="fa-solid fa-mobile-screen-button" />
                <p v-if="benefit.nfc" class="ml-2 font-medium">IN-STORE</p>
                <p v-if="!benefit.nfc && !Number(benefit.price)" class="ml-2 font-medium">INVOICE</p>
              </div>
            </div>
            <div class="w-5/12 h-full flex justify-end items-center gap-x-1.5 text-gray-600">
              <div :class="`h-6 w-6 ${userCartIds.includes(benefit.id) ? '': 'group'}`">
                <font-awesome-icon
                    v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0"
                    icon="fa-solid fa-shopping-cart"
                    @click.stop="toggleCart(benefit.id)"
                    :class="`group-hover:hidden h-6 w-6 transform hover:scale-110  xl:hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`"
                />
                <font-awesome-icon
                    v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0"
                    icon="fa-solid fa-cart-plus"
                    @click.stop="toggleCart(benefit.id)"
                    :class="`hidden group-hover:block h-6 w-6 transform hover:scale-110  xl:hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`"
                />
              </div>
<!--              <shopping-cart-icon v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0" @click.stop="toggleCart(benefit.id)" :class="`h-7 w-7 transform hover:scale-110  xl:hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`" />-->
              <heart-icon v-if="benefits_type !== 'fixed'" @click.stop="toggleFavorites(benefit.id)" :class="`h-7 w-7 transform hover:scale-110 mr-1 xl:hover:text-red-600 ${userFavoriteIds.includes(benefit.id) ? 'text-red-600': ''} transition duration-200 ease-in-out`" />
            </div>
          </div>

          <div v-if="!benefit.fixed" :class="`w-full h-2/6 pb-2`">
            <div :class="`h-1/2 p-2 pb-1  ${benefit.fixed ? 'w-full justify-center' : 'w-11/12'}`">
              <p :class="`text-sm leading-4 font-black line-clamp-2 ${benefit.fixed? 'text-left': 'text-left'}`">{{benefit.name}}</p>
            </div>
            <div>
              <p :class="`h-1/2 text-sm leading-4 px-2 text-gray-400 pt-3 line-clamp-2 overflow-hidden mr-1`" v-html="benefit.short_description"></p>
            </div>
          </div>
        </div>



        <!--          <div v-if="!benefit.fixed" :class="`w-full h-1/6`">-->
        <!--            <div :class="`flex items-center space-x-2 p-2 ${benefit.fixed ? 'w-full justify-center' : 'w-10/12'}`">-->
        <!--              <p :class="`text-sm font-medium text-ellipsis ${benefit.fixed? 'text-left': 'text-left'}`">{{benefit.name}}</p>-->
        <!--            </div>-->
        <!--            <p :class="`h-1/2 text-sm px-2 pt-1 text-gray-400 text-ellipsis overflow-hidden mr-2`">{{ extractContent(benefit.short_description) }}...</p>-->
        <!--          </div>-->
        <!--        </div>-->
      </li>
    </ul>

    <ul role="list" class="space-y-6" v-if="benefits && benefit_preview_type === 'list' && !benefits[0].fixed">
      <li
          v-for="benefit in benefits"
          class="h-24 col-span-1 bg-white rounded-lg cursor-pointer text-gray-700 shadow-xl md:transform hover:scale-105 transition duration-200 ease-in-out"
          @click="$router.push(`/benefit/${benefit.id}`)"
      >
        <div class="w-full h-full flex justify-between rounded-lg space-x-4">
          <div class="rounded-l-lg bg-cover w-40 h-full shadow-2xl" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-size: contain; background-position: center center; background-repeat: no-repeat;`"/>
          <p class="w-64 text-md font-bold my-auto">{{benefit.name}}</p>
          <p class="text-xs my-2 font-medium cut-text text-gray-500 w-3/12 text-justify overflow-hidden"> {{extractContent(benefit.short_description)}}</p>
          <div class="ml-auto flex justify-end items-center w-4/12">
            <div class="text-base-500 mr-4">
              <p class="font-normal line-through" v-if="!benefit.fixed && benefit.price > 0 && benefit.discount">{{monetary(benefit.price, benefit.currency)}}</p>
              <p class="font-semibold" v-if="!benefit.fixed && benefit.price > 0">{{monetary(benefit.price * (100 - benefit.discount) / 100, benefit.currency)}} </p>
            </div>
            <div class="flex w-1/3 pl-4 space-x-2">
              <shopping-cart-icon v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0" @click.stop="toggleCart(benefit.id)" :class="`hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
              <heart-icon v-if="benefits_type != 'fixed'" @click.stop="toggleFavorites(benefit.id)" :class="`hover:text-red-600 ${userFavoriteIds.includes(benefit.id) ? 'text-red-600': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
            </div>
            <div v-if="benefit.discount" class="mb-auto bg-base-600 rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
              -{{benefit.discount}}%
            </div>
            <!--          psudo element-->
            <div v-else class="mb-auto bg-white rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
            </div>
          </div>
        </div>
      </li>
    </ul>
    <a href="list" name="list" id="list"></a>
  </div>

  <list-loader v-if="loading" class="absolute top-0 left-0" />
</template>

<script>
import listLoader from "@/components/ui/listLoader.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import benefitList from "@/components/benefitList.vue";
import AdditionalFilters from "@/components/additionalFilters.vue";
import {HeartIcon, ShoppingCartIcon} from "@heroicons/vue/solid";
import Filters from '@/modules/benefits/UI/Filters.vue';
import monetary from '../../filters/monetary';

export default {
  name: "FlexibleBenefits",
  data() {
    return {
      loading: false
    }
  },
  components: {Filters, HeartIcon, ShoppingCartIcon, listLoader, benefitList, AdditionalFilters},
  computed: {
    ...mapGetters('auth', {
      userFavoriteIds: 'userFavoriteIds',
      userCartIds: 'userCartIds',
    }),
    ...mapState('benefits', ['benefits_type']),
    ...mapState('ui', ['benefit_preview_type']),
    ...mapGetters('auth', ['isAuth', 'user']),
    benefits() {
      if (this.$store.state.benefits.benefits) {
        return this.$store.state.benefits.benefits.results;
      }
    }
  },
  mounted() {
    this.init('flexible', Number(this.$route.params.catId));
    const _this = this
    window.onscroll = async () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 10;
      if (_this.$route.path.includes('/flexible-benefits') && _this.isAuth && !_this.loading) {
        if (bottomOfWindow) {
          _this.loading = true;
          await _this.load_mode()
              .then(() => _this.loading = false)
              .catch((e) => _this.loading = false)
              .finally(() => _this.loading = false);
        }
      }
    };
    this.initialPosition = this.$refs?.image?.offsetTop;
  },
  watch: {
    $route(){
      this.select_category(Number(this.$route.params.catId));
    }
  },
  methods: {
    monetary,
    ...mapActions('auth', {
      toggleFavorites: 'toggleFavorites',
      toggleCart: 'toggleCart'
    }),
    ...mapActions('benefits', ['fetchBenefits', 'fetchHome', 'load_mode', 'set_benefits_type']),
    ...mapActions('filters', ['select_category', 'select_sort', 'set_ordering']),
    ...mapMutations('benefits', ['toggle_benefit_fetch']),
    ...mapMutations('filters', ['reset_filters']),
    calculateBudgetPercent(spent, budget) {
      if (budget <= 0) {
        console.error("Budžet mora biti veći od 0.");
        return 0;
      }

      const percentage = (spent / budget) * 100;
      return String(100 - percentage.toFixed(2));
    },
    extractContent(s) {
      var span = document.createElement('span');
      span.innerHTML = s;
      span.classList.add('line-clamp-2')
      return span.textContent || span.innerText;
    },
    async init(benefitType, cat) {
      await this.set_benefits_type(benefitType).then(() => {
        this.select_category(cat);
      })
    },
  },
  unmounted() {
    // this.reset_filters();
  }

}
</script>

<style scoped>

</style>