<template>
  <div v-if="$store.state.benefits.home_page">
    <div v-if="benefits && (benefit_preview_type == 'grid' || benefits[0].fixed)" class="mb-6 mt-2 rounded-xl relative">
      <div class="absolute left-0 top-28 w-full z-20 hidden md:flex items-center justify-between">
        <button @click="goToPrevSlide" :class="`h-9 w-9 rounded-full shadow border border-gray-200 bg-white text-gray-700 transform -translate-x-5 bg-opacity-50 group hover:bg-opacity-75 transition duration-200 ease-in-out ${slideIndex === 0 ? 'cursor-not-allowed ' : ''}`">
           <font-awesome-icon icon="fa-angle-left" :class="`group-hover:text-base-600 font-bold ${slideIndex === 0 ? 'group-hover:text-gray-700' : ''}`" />
        </button>
        <button @click="goToNextSlide" :class="`h-9 w-9 rounded-full shadow border border-gray-200 bg-white text-gray-700 transform translate-x-5 bg-opacity-50 group hover:bg-opacity-75 transition duration-200 ease-in-out ${slideIndex === benefits.length ? 'cursor-not-allowed' : ''}`">
          <font-awesome-icon icon="fa-angle-right" :class="`group-hover:text-base-600 font-bold ${slideIndex === benefits.length ? 'group-hover:text-gray-700' : ''}`" />
        </button>
      </div>
      <Splide :options="splideOptions" aria-label="My Favorite Images" class="splide" :ref="type">
        <SplideSlide v-for="benefit in benefits" :key="benefit.id" @click.stop="benefit.fixed ? open_fixed(benefit) : $router.push(`/benefit/${benefit.id}`)" class="z-10 h-56 w-56 md:h-72 md:w-80 bg-white rounded-lg cursor-pointer hover:text-base-500 text-gray-700 transform hover:scale-98 transition duration-200 ease-in-out">
          <div class="h-full w-full rounded-xl">
            <div :class="`rounded-tr-xl rounded-tl-xl w-full ${benefit.fixed ? 'h-3/6' : 'h-3/6'} bg-gray-400 bg-cover`" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-position: center;`">
              <div v-if="benefit.discount" class="w-14 bg-base-600 text-white text-center font-medium p-2 rounded-bl-xl rounded-tr-xl ml-auto">-{{benefit.discount}}%</div>
            </div>
            <div style="background-color: rgb(233,238,251); height: 15%;" class="px-1 w-full flex justify-between items-center" v-if="benefits_type !== 'fixed'">
              <div class="w-7/12 flex items-center justify-start">
                <div v-if="benefit.price || benefit.discount" class="space-y-0.5 tracking-tight">
                  <p class="font-medium text-sm md:text-base mr-2 ml-1 leading-3" v-if="!benefit.nfc && !benefit.fixed && benefit.price > 0">{{benefit.price_discount_currency}}</p>
                  <p class="font-medium text-gray-400 line-through text-sm md:text-base ml-1 leading-3" v-if="!benefit.fixed && benefit.price > 0 && benefit.discount">{{benefit.price_currency}}</p>
                  <div v-else></div>
                </div>

                <div class="flex justify-center items-center" v-if="(!benefit.nfc && !Number(benefit.price)) || (benefit.nfc)">
                  <!--            <img src="/icon2.png" v-if="!benefit.nfc && !Number(benefit.price)" class="ml-2 transform scale-80">-->
                  <!--            <img src="/nfc-icon.png" v-if="benefit.nfc" class="ml-2">-->
                  <font-awesome-icon v-if="!benefit.nfc && !Number(benefit.price)" icon="fa-solid fa-file-invoice-dollar" class="ml-2" />
                  <font-awesome-icon v-if="benefit.nfc" icon="fa-solid fa-mobile-screen-button" class="ml-2" />
                  <p v-if="benefit.nfc" class="ml-2">IN-STORE</p>
                  <p v-if="!benefit.nfc && !Number(benefit.price)" class="ml-2 font-medium">INVOICE</p>
                </div>
              </div>
              <div class="w-5/12 h-full flex justify-end items-center gap-x-1.5 text-gray-600">
                <div :class="`h-6 w-6 ${userCartIds.includes(benefit.id) ? '': 'group'}`">
                  <font-awesome-icon
                      v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0"
                      icon="fa-solid fa-shopping-cart"
                      @click.stop="toggleCart(benefit.id)"
                      :class="`group-hover:hidden h-6 w-6 transform hover:scale-110  xl:hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`"
                  />
                  <font-awesome-icon
                      v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0"
                      icon="fa-solid fa-cart-plus"
                      @click.stop="toggleCart(benefit.id)"
                      :class="`hidden group-hover:block h-6 w-6 transform hover:scale-110  xl:hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`"
                  />
                </div>
<!--                <shopping-cart-icon v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0" @click.stop="toggleCart(benefit.id)" :class="`h-7 w-7 transform hover:scale-110  xl:hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`" />-->
                <heart-icon v-if="benefits_type !== 'fixed'" @click.stop="toggleFavorites(benefit.id)" :class="`h-7 w-7 transform hover:scale-110 mr-1 lg:hover:text-red-600 ${userFavoriteIds.includes(benefit.id) ? 'text-red-600': ''} transition duration-200 ease-in-out`" />
              </div>
            </div>

            <div :class="`w-full h-2/6`">
              <div :class="`h-1/2 px-2 py-1 md:pt-2 md:p-2 pb-1 ${benefit.fixed ? 'w-full justify-center' : 'w-11/12'}`">
                <p :class="`leading-4 text-sm font-black line-clamp-2 ${benefit.fixed? 'text-left': 'text-left'}`">{{benefit.name}}</p>
              </div>
              <div>
                <p :class="`h-1/2 leading-4 text-sm line-clamp-2 px-2 pt-1 md:pt-2 text-gray-400 mr-2 font-normal`">{{ extractContent(benefit.short_description) }}</p>
              </div>

<!--              <div v-if="benefit.discount" class="w-2/12 bg-base-600 rounded-bl-lg text-white text-center font-semibold p-2 rounded-bl-xl">-{{benefit.discount}}%</div>-->
            </div>
          </div>

        </SplideSlide>
      </Splide>
    </div>
  </div>






  <div v-if="!$store.state.benefits.home_page">

    <ul  role="list" class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 py-4" v-if="benefits && (benefit_preview_type === 'grid' || benefits[0].fixed)">
      <li
        v-for="benefit in benefits"
        :key="benefit.id"
        class="h-72 col-span-1 bg-white rounded-lg shadow-xl cursor-pointer hover:text-base-500 text-gray-700 md:transform hover:scale-105 transition duration-200 ease-in-out"
        @click="benefit.fixed? open_fixed(benefit) :$router.push(`/benefit/${benefit.id}`)"
      >
        <!--      <benefit-box :data="benefit" :key="benefit.id" />-->

        <div class="h-full w-full rounded-xl">

          <div v-if="benefit.fixed" :class="`w-full h-1/6 flex items-center justify-center transform translate-y-3`">
            <div :class="`flex items-center space-x-2 p-2  ${benefit.fixed ? 'w-full justify-center' : 'w-10/12'}`">
              <p :class="`text-sm font-medium text-ellipsis ${benefit.fixed? 'text-left': 'text-left'}`">{{benefit.name}}</p>
            </div>

<!--            <div v-if="benefit.discount" class="w-2/12 bg-base-600 rounded-bl-lg text-white text-center font-semibold p-2 rounded-bl-xl">-{{benefit.discount}}%</div>-->
          </div>

          <div :class="`rounded-tr-xl rounded-tl-xl w-full ${benefit.fixed ? 'h-5/6' : 'h-3/6'} bg-gray-400 bg-cover`" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-position: center;`">
            <div v-if="benefit.discount" class="w-2/12 bg-base-600 text-white text-center font-semibold p-2 rounded-bl-xl rounded-tr-xl ml-auto">-{{benefit.discount}}%</div>
          </div>


          <div style="background-color: rgb(233,238,251);  height: 15%;" class="px-1 w-full flex justify-between items-center" v-if="benefits_type !== 'fixed'">
            <div class="w-7/12 flex items-center justify-start">
              <div v-if="benefit.price || benefit.discount" class="space-y-1">
                <p class="font-bold ml-1 leading-3" v-if="!benefit.nfc && !benefit.fixed && benefit.price > 0">{{benefit.price_discount_currency}}</p>
                <p class="font-medium text-gray-400 line-through text-sm md:text-base ml-1 mr-2 leading-3" v-if="!benefit.fixed && benefit.price > 0 && benefit.discount">{{benefit.price_currency}}</p>
                <div v-else></div>
              </div>
              <ul role="list" class="space-y-6" v-if="benefits && benefit_preview_type === 'list' && !benefits[0].fixed">
                <li
                    v-for="benefit in benefits"
                    class="h-24 col-span-1 bg-white rounded-lg cursor-pointer text-gray-700 shadow-xl md:transform hover:scale-105 transition duration-200 ease-in-out"
                    @click="$router.push(`/benefit/${benefit.id}`)"
                >
                  <div class="w-full h-full flex justify-between rounded-lg space-x-4">
                    <div class="rounded-l-lg bg-cover w-40 h-full shadow-2xl" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-size: contain; background-position: center center; background-repeat: no-repeat;`"/>
                    <p class="w-64 text-md font-bold my-auto">{{benefit.name}}</p>
                    <p class="w-64 text-xs my-2 font-medium cut-text text-gray-500 w-3/12 text-justify"> {{extractContent(benefit.short_description)}} </p>

                    <div class="ml-auto flex justify-end items-center w-4/12">
                      <div class="text-base-500 mr-4">
                        <p class="font-normal line-through" v-if="!benefit.fixed && benefit.price > 0 && benefit.discount">{{monetary(benefit.price, benefit.currency)}}</p>
                        <p class="font-semibold" v-if="!benefit.fixed && benefit.price > 0">{{monetary(benefit.price * (100 - benefit.discount) / 100, benefit.currency)}}</p>
                      </div>
                      <div class="flex w-1/3 pl-4 space-x-2">
                        <shopping-cart-icon v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0" @click.stop="toggleCart(benefit.id)" :class="`hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
                        <heart-icon v-if="benefits_type!='fixed'" @click.stop="toggleFavorites(benefit.id)" :class="`hover:text-red-600 ${userFavoriteIds.includes(benefit.id) ? 'text-red-600': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
                      </div>
                      <div v-if="benefit.discount" class="mb-auto bg-base-600 rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
                        -{{benefit.discount}}%
                      </div>
                      <!--          psudo element-->
                      <div v-else class="mb-auto bg-white rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="flex justify-center items-center px-1" v-if="(!benefit.nfc && !Number(benefit.price)) || (benefit.nfc)">
                <font-awesome-icon v-if="!benefit.nfc && !Number(benefit.price)" icon="fa-solid fa-file-invoice-dollar" />
                <font-awesome-icon v-if="benefit.nfc" icon="fa-solid fa-mobile-screen-button" />
                <p v-if="benefit.nfc" class="ml-2 font-medium">IN-STORE</p>
                <p v-if="!benefit.nfc && !Number(benefit.price)" class="ml-2 font-medium">INVOICE</p>
              </div>
            </div>
            <div class="w-5/12 h-full flex justify-end items-center gap-x-1 text-gray-600">
              <shopping-cart-icon v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0" @click.stop="toggleCart(benefit.id)" :class="`h-7 w-7 transform hover:scale-110  xl:hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`" />
              <heart-icon v-if="benefits_type !== 'fixed'" @click.stop="toggleFavorites(benefit.id)" :class="`h-7 w-7 transform hover:scale-110 mr-1 xl:hover:text-red-600 ${userFavoriteIds.includes(benefit.id) ? 'text-red-600': ''} transition duration-200 ease-in-out`" />
            </div>
          </div>

           <div v-if="!benefit.fixed" :class="`w-full h-2/6 pb-2`">
              <div :class="`h-1/2 p-2 pb-1  ${benefit.fixed ? 'w-full justify-center' : 'w-11/12'}`">
                <p :class="`text-sm leading-4 font-black line-clamp-2 ${benefit.fixed? 'text-left': 'text-left'}`">{{benefit.name}}</p>
              </div>
             <div>
               <p :class="`h-1/2 text-sm leading-4 px-2 text-gray-400 pt-3 line-clamp-2 overflow-hidden mr-1`" v-html="benefit.short_description"></p>
             </div>
            </div>
          </div>



<!--          <div v-if="!benefit.fixed" :class="`w-full h-1/6`">-->
<!--            <div :class="`flex items-center space-x-2 p-2 ${benefit.fixed ? 'w-full justify-center' : 'w-10/12'}`">-->
<!--              <p :class="`text-sm font-medium text-ellipsis ${benefit.fixed? 'text-left': 'text-left'}`">{{benefit.name}}</p>-->
<!--            </div>-->
<!--            <p :class="`h-1/2 text-sm px-2 pt-1 text-gray-400 text-ellipsis overflow-hidden mr-2`">{{ extractContent(benefit.short_description) }}...</p>-->
<!--          </div>-->
<!--        </div>-->
      </li>
    </ul>

    <ul role="list" class="space-y-6" v-if="benefits && benefit_preview_type === 'list' && !benefits[0].fixed">
      <li
          v-for="benefit in benefits"
          class="h-24 col-span-1 bg-white rounded-lg cursor-pointer text-gray-700 shadow-xl md:transform hover:scale-105 transition duration-200 ease-in-out"
          @click="$router.push(`/benefit/${benefit.id}`)"
      >
        <div class="w-full h-full flex justify-between rounded-lg space-x-4">
          <div class="rounded-l-lg bg-cover w-40 h-full shadow-2xl" :style="`background-image: url('${getBaseImg(benefit.pictures)}'); background-size: contain; background-position: center center; background-repeat: no-repeat;`"/>
          <p class="w-64 text-md font-bold my-auto">{{benefit.name}}</p>
          <p class="w-64 text-xs my-2 font-medium cut-text text-gray-500 w-3/12 text-justify"> {{extractContent(benefit.short_description)}} </p>

          <div class="ml-auto flex justify-end items-center w-4/12">
            <div class="text-base-500 mr-4">
              <p class="font-normal line-through" v-if="!benefit.fixed && benefit.price > 0 && benefit.discount">{{monetary(benefit.price, benefit.currency)}}</p>
              <p class="font-semibold" v-if="!benefit.fixed && benefit.price > 0">{{monetary(benefit.price * (100 - benefit.discount) / 100, benefit.currency)}}</p>
            </div>
            <div class="flex w-1/3 pl-4 space-x-2">
              <shopping-cart-icon v-if="!benefit.fixed && !benefit.special_offer && benefit.price > 0" @click.stop="toggleCart(benefit.id)" :class="`hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
              <heart-icon v-if="benefits_type!='fixed'" @click.stop="toggleFavorites(benefit.id)" :class="`hover:text-red-600 ${userFavoriteIds.includes(benefit.id) ? 'text-red-600': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
            </div>
            <div v-if="benefit.discount" class="mb-auto bg-base-600 rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
              -{{benefit.discount}}%
            </div>
            <!--          psudo element-->
            <div v-else class="mb-auto bg-white rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>



  <fixed-benefit :open="fixed_benefit_open" @close="fixed_benefit_open = false" :benefit="fixed_benefit" />
</template>

<script>
import {ShoppingCartIcon, HeartIcon} from "@heroicons/vue/solid"
import {mapActions, mapGetters, mapState} from "vuex";
import fixedBenefit from "@/components/ui/FixedBenefit";
import benefitBox from "@/components/benefitBox";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';

export default {
  name: "benefitList",
  data() {
    return {
      slideIndex: 0,
      splideArrows: null,
      fixed_benefit: null,
      fixed_benefit_open: false,
      splideOptions: {
        type   : 'slide',
        speed: 300,
        autoWidth: true,
        autoHeight: true,
        gap: 20,
        perPage: 1,
        pagination: false,
        arrows: false,
        // arrows: { prev: <div>prevv</div>, next: <div>next</div> },
        autoScroll: {
          speed: 1,
        },
        drag: 'free',
        mediaQuery: 'min',
        breakpoints: {
          1024: {
            // arrows: true,
            gap: 30,
          },
          1280: {
            // arrows: true,
            gap: 30,
          }
        },
        classes: {
          // Add classes for arrows.
          track: 'visible-over',
          arrows: 'splide__arrows',
          arrow : 'splide__arrow',
          prev  : 'splide__arrow--prev transform -translate-x-8 -translate-y-3 bg-white shadow hover:bg-base-500',
          next  : 'splide__arrow--next transform translate-x-8 -translate-y-3 bg-white shadow hover:bg-base-500',
        },
      },
    }
  },
  props: ['benefits', 'type'],
  components: {
    ShoppingCartIcon,
    HeartIcon,
    fixedBenefit,
    benefitBox,
    SplideTrack,
    Splide,
    SplideSlide,
  },
  mounted() {

  },
  computed: {
    isViewAll() {
      return this.benefits_type === 'flexible' || this.benefits_type === 'special' || this.benefits_type === 'fixed';
    },
    filteredBenefits() {
      if (this.get_type === 'invoice') {
        return this.benefits.filter((b) => !b.nfc && !b.price);
      }
      if (this.get_type === 'in_store') {
        return this.benefits.filter((b) => benefit.nfc);
      }
      return this.benefits;
    },
    isDisabledNext() {
      if (this.type && this.$refs[this.type]) {
        return true
      }
      return false
    },
    isDisabledPrevious() {
      if (this.type && this.$refs[this.type]?.splide.index === 0) {
        return true;
      }
      return false;
    },
    ...mapState('benefits', ['benefits_type']),
    ...mapState('ui', ['benefit_preview_type']),
    ...mapGetters('filters', ['get_type']),
    ...mapGetters('auth', {
      userFavoriteIds: 'userFavoriteIds',
      userCartIds: 'userCartIds',
    })
  },
  methods: {
    ...mapActions('auth', {
      toggleFavorites: 'toggleFavorites',
      toggleCart: 'toggleCart'
    }),
    test() {
      console.log('drag')
    },
    goToPrevSlide() {
      if (this.$refs[this.type]) {
        this.$refs[this.type].splide.go('-1');
        if (this.slideIndex > 0) {
          this.slideIndex--;
        }
      }
    },
    goToNextSlide() {
     if (this.$refs[this.type]) {
        this.$refs[this.type].splide.go('+1');
        if (this.slideIndex < this.benefits.length) {
          this.slideIndex++;
        }
      }
    },
    open_fixed(b) {
      this.fixed_benefit = b
      this.fixed_benefit_open = true
    },
    close_fixed() {
      this.fixed_benefit_open = false
      this.fixed_benefit = null
    },
    extractContent(s) {
      var span = document.createElement('span');
      span.innerHTML = s;
      span.classList.add('line-clamp-2')
      return span.textContent || span.innerText;
    },
  }
}
</script>

<style scoped>

.cut-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.visible-over {
  overflow: visible !important;
}

.custom-shadow {
 box-shadow: -2px -2px 331px -38px rgba(0,0,0,0.75);
-webkit-box-shadow: -2px -2px 331px -38px rgba(0,0,0,0.75);
-moz-box-shadow: -2px -2px 331px -38px rgba(0,0,0,0.75);
  opacity: 1000;
  overflow: visible;
}
</style>